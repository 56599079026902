@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.6.1/css/font-awesome.min.css";
@import url(https://fonts.googleapis.com/css?family=Oswald:400,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,400italic,600italic);

// Bootstrap Overrides
$headings-font-family: 'Oswald', sans-serif;
$font-family-sans-serif: 'Open Sans', sans-serif;

$brand-primary: #D2232A;

$navbar-default-bg: #C1C1C1;
$navbar-default-link-color: #404040;
$navbar-border-radius: 0;

$btn-border-radius-base: 0;
$btn-border-radius-large: 0;
$btn-border-radius-small: 0;

// Import Bootstrap

@import "bootstrap";

// General Styles

img {
  max-width: 100%;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.list {
  li {
    line-height: 1.6em;
    padding-left: 15px;
    text-indent: -14.5625px;
    &:before {
      font-family: "FontAwesome";
      content: "\f0c8";
      color: #D2232A;
      font-size: 10px;
      position: relative;
      top: -2px;
      margin-right: 6px;
    }
  }
}

form legend {
  margin-top: 15px;
}

ol.breadcrumb {
  padding-left: 0;
  background: none;
}

h2.caption {
  color: white;
  background: url(../images/dark_bg.png) repeat;
  font-size: 20px;
  padding: 6px 10px;
  display: inline-block;
  line-height: 1.2;
  @media (min-width: 768px) {
    font-size: 34px;
  }
}

// Header

header {
  // background-color: #9B0C00;
  background: url(../images/asfalt-dark.png), linear-gradient(to bottom, #9b0c00 0%, darken(#9b0c00, 7%) 100%);
  color: white;
  padding: 10px 0;
  img.brand {
    width: 95px;
    float: left;
    padding-right: 8px;
    @media (min-width: 768px) {
      width: 160px;
      padding-right: 15px;
    }
  }
  .company-name {
    float: left;
    @media (min-width: 768px) {
      margin-top: 4px;
    }
  }
  .header-left {
    text-align: center;
    @media (min-width: 768px) {
      text-align: left;
    }
  }
  .branding {
    display: inline-block;
    cursor: pointer;
  }
  span.company {
    font-size: 24px;
    font-family: $headings-font-family;
    font-weight: bold;
    letter-spacing: -.02em;
    text-align: left;
    color: #E8E5E6;
    line-height: 22px;
    display: block;
    float: left;
    @media (min-width: 768px) {
      font-size: 38px;
      line-height: 34px;

    }
  }
  span.tag {
    font-size: 14px;
    display: block;
    color: #F8B7B3;
    text-align: right;
    letter-spacing: -.01em;
    font-weight: normal;
    position: relative;
    top: 2px;
    clear: both;
    width: 100%;
    font-family: $font-family-sans-serif;
    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
  .header-right {
    text-align: right;
    clear: both;
    @media (min-width: 768px) { clear: none; }
    span {
      display: block;
      font-size: 18px;
      margin-top: 14px;
      float: left;
      font-family: $headings-font-family;
      @media (min-width: 768px) {
        margin-top: 5px;
        float: none;
        font-size: 22px;
      }
      &.italics {
        font-style: italic;
        margin-bottom: 0;
        margin-top: 2px;
        font-size: 18px;
        color: #F8B7B3;
      }
    }
    span i {
      color: #FB8F8E;
    }
  }
  .ieda {
    float: right;
    margin-top: 10px;
    @media (min-width: 768px) {
      float: none;
      margin-top: 0;
    }
  }
  .ieda img {
    width: 105px;
  }
}

// Navbar
ul.nav.navbar-nav {
  font-size: $font-size-large;
  text-align: center;
  float: none;
  > li {
    display: inline-block;
    float: left;
    width: 50%;
    @media (min-width: 768px) {
      float: none;
      width: auto;
    }
  }
}

.navbar-default {
  border: none;
  background: #DADADA;
  margin-bottom: 0;
  @media (min-width: 768px) { @include gradient-vertical($start-color: #FFFFFF, $end-color: #DADADA); }
  .navbar-toggle {
    border-color: #A5A5A5;
  }
}

.navbar-header {
  @include gradient-vertical($start-color: #FFFFFF, $end-color: #DADADA);
}

.navbar-default .navbar-nav > li > a.active, .navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
  @include gradient-vertical($start-color: lighten(#DADADA, 10%), $end-color: darken(#DADADA, 7%));
  color: #000;
}

.navbar p.navbar-text {
  float: left;
  padding-left: 15px;
}

// Content
div > .container {
  background: white;
}

.loading {
  min-height: 400px;
  .content {
    margin-top: 40px;
    text-align: center;
  }
  .progress-bar {
    background-color: darken($brand-primary, 10%);
  }
}

section {
  padding: 40px 0;
  &.shaded {
    background: #F1F1F1 url(../images/asfalt-dark.png) repeat center;
  }
  &.content {
    min-height: 400px;
  }
  &.callout {
    background: url(../images/inventory-narrow.jpg) no-repeat center;
    background-size: cover;
    height: 450px;
    @media (min-width: 768px) {
      height: 550px;
    }
  }
  .container {
    position: relative;
  }
  h1:first-child {
    margin-top: 0;
  }
  h2:first-child {
    margin-top: 0;
  }
  h3:first-child {
    margin-top: 0;
  }
}

.carousel-inner {
  .item {
    background: #151515;
    > img {
      margin: 0 auto;
    }
    .carousel-caption {
      bottom: auto;
      top: 0;
      left: 10%;
      right: 10%;
    }
    @media (min-width: 768px) {
      &:nth-child(1) {
        .carousel-caption {
          top: 30%;
          bottom: auto;
        }
      }
      &:nth-child(2) {
        .carousel-caption {
          bottom: 60px;
          top: auto;
        }
      }
      &:nth-child(3) {
        .carousel-caption {
          bottom: auto;
          top: 40px;
        }
      }
    }
  }
}

ul.list.offer {
  text-shadow: 1px 1px 0px #FFF;
}

.excavator {
  background: url(../images/excavator.png) no-repeat;
  background-position: right center;
  height: 300px;
  width: 642px;
  position: absolute;
  right: 0;
  top: -100px;
  background-size: contain;
}

.inventory-buttons {
  a {
    width: 300px;
    height: 125px;
    background-repeat: no-repeat;
    display: block;
    text-indent: -9999em;
    margin: 0 auto;
    margin-bottom: 25px;
    @media (min-width: 992px) { margin-bottom: 0; }
    &.equip-for-sale {
      background-image: url(../images/equipment_for_sale.jpg);
      @media (min-width: 992px) { float: left; }
      &:hover {
        background-position: 0 bottom;
      }
    }
    &.equip-for-rent {
      background-image: url(../images/equipment_for_rent.jpg);
      &:hover {
        background-position: 0 bottom;
      }
    }
    &.attach-for-sale {
      background-image: url(../images/attachments_for_sale.jpg);
      margin-bottom: 0;
      @media (min-width: 992px) { float: right; }
      &:hover {
        background-position: 0 bottom;
      }
    }
  }
}

// Inventory
.inventory-list {
  .media.machine {
    margin-top: 20px;
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 20px;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      border-bottom: none;
    }
    img.media-object {
      max-width: inherit;
      width: 100px;
      @media (min-width: 768px) { width: 170px; }
    }
    h4 {
      margin-bottom: 5px;
      margin-top: 0;
      color: $brand-primary;
      a {
        margin: 0;
        color: $headings-color;
        &:hover {
          text-decoration: none;
          color: darken($brand-primary, 10%);
        }
      }
    }
    .spec {
      margin-top: 5px;
    }
    p {
      height: $font-size-base*$line-height-small*2;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      line-height: $line-height-small;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      color: lighten($text-color, 20%);
      margin-bottom: 0;
      padding-right: 10px;
      @media (min-width: 768px) { padding-right: 0px; }
    }
    strong {
      display: inline-block;
      width: 100%;
    }
  }
}

.inventory_show {
  min-height: 400px;

  h2 {
    margin-top: 0;
    color: $brand-primary;
    font-weight: 400;
  }
  h3 {
    margin-top: 10px;
  }
  .details {
    strong {
      display: inline-block;
      width: 100%;
    }
    > div {
      margin-bottom: 10px;
    }
    .col-md-12:nth-of-type(1) {
      display: none;
    }
  }
  .selected_image {
    text-align: center;
    @media (min-width: 992px) { height: 341px; }
    @media (min-width: 1200px) { height: 416px; }
    img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 416px;
    }
  }
  .controls {
    clear: both;
    margin-bottom: 30px;
    .btn-warning {
      margin-right: 0;
    }
  }
  .thumbnails {
    width: 100%;
    margin-top: 6px;
    margin-bottom: 6px;
    @media (min-width: 992px) { height: 109.25px; }
    @media (min-width: 1200px) { height: 134.25px; }
    img {
      height: auto;
      width: auto;
      background: #000;
      max-width: 100%;
    }
    .thumb {
      cursor: pointer;
    }
    .row {
      margin-right: -3px;
      margin-left: -3px;
    }
    .col-md-3 {
      padding-right: 3px;
      padding-left: 3px;
    }
  }
}

// machine images
.machine {
  margin-bottom: 40px;

  &__pictures {
    img {
      max-width: 100%;
    }
    .btn {
      margin-top: .5rem;
    }
  }

  .machine__thumbnails {
    display: -webkit-flex;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  &__picture-box {
    flex-basis: calc(33% - .25rem);
    margin: .5rem 0 0;

    &:first-child {
      flex-basis: 100%;
      margin: 0;
      text-align: center;
    }

    &:nth-child(3n+4) {
      margin-right: 0;
    }

    &:nth-child(n+8) {
      display: none;
    }
  }

  &__picture {
    max-width: 100%;
  }
}

// Contact
.info {
  div > span {
    display: block;
  }
}

.map {
  position: relative;
  iframe {
    width: 100%;
  }
}

.people {
  .name {
    font-weight: bold;
    display: block;
  }
  .title {
    display: block;
    text-decoration: italics;
    margin-bottom: 5px;
  }
  img {
    margin-top: 25px;
    margin-bottom: 5px;
  }
}

// Rental
a.rental-rates {
  margin: 0 auto;
  margin-top: 20px;
  display: block;
  width: 430px;
  height: 95px;
  background: url(../images/rental_btn.jpg) no-repeat;
  &:hover {
    background-position: 0 bottom;
  }
}

// Credit Application
.sign {
  margin-top: 30px;
}

// Footer
footer {
  padding: 50px 0;
  background: #292929;
  color: white;
  h3 {
    margin-top: 0px;
  }
  ul {
    overflow: auto;
    margin-bottom: 15px;
    @media (min-width: 768px) { margin-bottom: 0; }
  }
  ul > li {
    float: left;
    width: 50%;
    a {
      color: #D84A50;
    }
  }
  span {
    color: darken(white, 20%);
  }
}
